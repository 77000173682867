.playlist {
  /* background-color: #000; */
  color: #fff;
  padding: 1.5vh;
  max-height: 50vh;
  overflow: hidden;
  overflow-y: auto;
  border-radius: 2vh;
  scrollbar-width: auto; /* auto, thin, or none */
  scrollbar-color: #444 #000; /*Thumb and track color*/
}

.playlist-item {
  display: flex;
  align-items: center;
  margin-bottom: 1vh;
  cursor: pointer;
  padding: 0.5vh 0;
}

.playlist-artwork {
  width: 8vh;
  height: 8vh;
  margin-right: 1vh;
  border-radius: 3vh;
}

.playlist-title {
  font-size: 2vh;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Custom scrollbar styles */
.playlist::-webkit-scrollbar {
  width: 3vh;
  border-radius: 1.5vh;
}

.playlist::-webkit-scrollbar-track {
  background: #000; /* Track color */
  border-radius: 1.5vh;
}

.playlist::-webkit-scrollbar-thumb {
  background: #444; /* Thumb color */
  border-radius: 0.5vh;
}

.playlist::-webkit-scrollbar-thumb:hover {
  background: #555; /* Thumb hover color */
  border-radius: 1.5vh;
}