.navBarContainer.shared-max-width {
  /* max-width: 600px; */
  /* position: fixed;
  top: 0;
  left: 0;
  right: 0; */
  /* z-index: 1000; */
  /* max-width: var(--max-container-width); */
  /* margin-left: auto;
  margin-right: auto; */
  /* width: 100%; */
}

.Pages {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Pages img.rounded-image {
  width: 92%;
  filter: none;
  border-radius: 35% !important;
  overflow: hidden;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.1);
  transition: 0.4s filter ease;
}

.Pages .underline {
  text-decoration: none;
}

.Pages .menu-text {
  font-size: 1.5em;
  text-decoration: none;
}

 /* small screens */
@media (max-width: 576px) {
  .Pages img.rounded-image {
    /* max-width: 100%; */
    width: 92%;
    height: auto;
  }
  .navBarContainer.shared-max-width {
    padding: 0;
  }

  .Pages {
    /* padding: 5px; */
    /* flex: 0 0 33.333%;
    max-width: 33.333%; */
  }

  .Pages .menu-text {
    font-size: 1.0em;
    text-decoration: none;
  }


  /* Override Bootstrap's col-4 class for small screens */
  /* .row .col-4 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  } */
}