:root {
  --max-container-width: 700px;
}

.shared-max-width {
  width: 100%;
  margin: auto;
}

.list-group li iframe {
  /* width: calc(100% - 16%); */
  height: auto;
  display: block; /* To prevent inline default space */
}

@media (min-width: 701px) {
  .shared-max-width {
    max-width: var(--max-container-width);
  }
}

@media (max-width: 700px) {
  .shared-max-width {
    max-width: 100%;
  }
}
