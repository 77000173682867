.player {
  padding: 1.5vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 50vh;
}

.elapsed-time,
.remaining-time {
  color: #fff;
  font-size: 2vh;
  /* padding: 1 1vh; */
  max-height: 50vh;
  overflow: hidden;
}

.track-artwork {
  /* max-width: 100%; */
  max-height: 37vh;
  object-fit: contain;
  border-radius: 10vh;
  margin: 0 auto;
}

.track-title {
  margin: 0.7vw 0;
  text-align: center;
  font-size: 2.1vh;
}

.player-controls {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 33vh;
}

/* Ensure that the container of the progress bar takes the full width */
.time-controls {
  width: 100%;
  box-sizing: border-box;
  padding: 0 1vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.progress-bar {
  width: calc(100% - 2vh);
  -webkit-appearance: none;
  appearance: none;
  height: 4vh;
  background: #333;
  border-radius: 1vh;
  margin: 0.5vh 0;
  overflow: visible;
  touch-action: pan-x;
}

/* range input progress bar */
.progress-bar::-webkit-slider-runnable-track {
  background: linear-gradient(to right, #00f 0%, #00f var(--progress), #333 var(--progress), #333 100%);
  border-radius: 1vh;
}

.progress-bar::-webkit-slider-thumb {
  -webkit-appearance: none;
  transition: none;
  /* margin-top: -10px; */
  appearance: none;
  width: 4vh;
  height: 4vh;
  background: #fff;
   /* Circular thumb */
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  top: 50%;
  z-index: 2;
}

/* For Firefox */
.progress-bar::-moz-range-thumb {
  width: 4vh;
  height: 4vh;
  background: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

/* For IE */
.progress-bar::-ms-thumb {
  width: 4vh;
  height: 4vh;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
}

button {
  background: transparent;
  border: none;
  color: #fff;
  font-size: 1vh;
  cursor: pointer;
}

/* @media (max-width: 600px) {
  .player-controls {
    max-width: 200px;
  }
} */
