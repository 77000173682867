.funk-page {
  background-color: purple;
}

/* .article {
  x: 'purple';
  padding: 0 8%;
  max-width: 600px;
  margin: auto;
} */

.list-group {
  list-style-type: none; /* Remove bullet points from list */
  padding: 0; /* Remove default padding */
}

.list-group li {
  margin-bottom: 15px;
}
