.mixTableContainer {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  grid-gap: 20px;
}

.mixText {
  text-align: center;
  color: white;
}

/* .mixTableHeader {
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  align-content: center;
} */

.mixRows {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 20px;
  background-color: transparent;
  color: #809800;
  text-align: center;
}

.search-form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: rgb(34, 56, 104);
  background-clip: padding-box;
  border: 1px solid #1c446c;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
