.house-page {
  background-color: rgb(65, 111, 75);
}

/* .article {
  width: 92%;
  max-width: 600px;
  margin: auto;
} */


/* .article {
  background-color: '#0000FF';
  padding: 0 8%;
  max-width: 600px;
  margin: auto;
} */

.list-group {
  list-style-type: none; /* Remove bullet points from list */
  /* padding: 0; */
}

/* .list-group li {
  margin-bottom: 15px;
} */
