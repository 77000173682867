.Social {
  display: flex;
  flex-direction: row;
  width: 100%;
  /*margin: 5px auto;*/
  margin: 1vh 1vh 1vh 1vh;
  padding: 1vh 1vh 1vh 1vh;
  align-items: center;
  justify-content: center;
}

.Podcast {
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: 5px auto;
    /* margin: 0.5vh 1vh 1vh 1vh; */
  padding: 1vh 1vh 1vh 1vh;
  align-items: center;
  justify-content: center;
}
