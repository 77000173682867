.details-panel {
  padding: 1vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 200vh;
}

.close-details-button {
  padding: 1vh 2vh;
  border: none;
  border-radius: 5vh;
  background-color: lightgray;
  color: white;
  font-size: 2.7vh;
  width: 80%;
  text-align: center;
  /* margin-top: 1vh; */
  cursor: pointer;
}

.close-details-button:hover {
  background-color: gray;
}
