.page.shared-max-width {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.1);
  padding-left: 1%;
  padding-right: 1%;
  /* background-color: blue; */
}

.page img {
  filter: none;
}
.page:hover img {
  filter: contrast(1.25);
  transition: 0.6s filter ease;
}
.page h1 {
  text-align: center;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 2rem;
  line-height: inherit;
  white-space: nowrap;
}

/* .backToBlack {
  background-color: "black";
} */
