.player-container.shared-max-width {
  /* background-color: #000; */
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding-top: 270px; */
  padding: 3vh;
  /* padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px; */
  /* max-width: 800px; */
  /* margin: auto; */
  width: 100%;
}

.player-container .switch-button {
  background-color: cadetblue;
  color: white;
  border: none;
  border-radius: 3.5vh;
  padding: 0.7vh 0.7vh;
  width: 100%;
  margin-top: 4.5vh;
  margin-bottom: 1vh;
  cursor: pointer;
  font-size: 2.5vh;
}